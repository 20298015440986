import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'react';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  color?: 'primary' | 'secondary' | 'danger';
};

const Button: FC<ButtonProps> = ({
  color = 'primary',
  className,
  children,
  ...rest
}) => {
  const colorClasses = rest.disabled
    ? 'bg-gray-200 text-gray-500'
    : color === 'primary'
    ? 'bg-primary hover:bg-primary-300 text-white'
    : color === 'danger'
    ? 'bg-error text-white'
    : 'bg-secondary text-white';
  return (
    <button
      type="button"
      {...rest}
      className={clsx(
        `group relative w-full flex justify-center 
        py-2 px-4 
        border border-transparent 
        text-sm font-medium rounded-md 
        focus:outline-none`,
        colorClasses,
        className
      )}
    >
      {children}
    </button>
  );
};

export default Button;
