import Breadcrumbs from '@/components/Breadcrumbs';
import Button from '@/components/Button';
import Layout from '@/components/Layout';
import PlantWater from '@/components/PlantWater';
import SEO from '@/components/SEO';
import { Plant } from '@/lib/connectors/plants';
import { usePlants } from '@/lib/data/plants';
import { TreeGuild, useTreeGuild } from '@/lib/data/treeGuild';
import { useTree } from '@/lib/data/trees';
import clsx from 'clsx';
import { navigate, PageProps } from 'gatsby';
import * as React from 'react';
import { FC, useState } from 'react';
import { useSearchParam } from 'react-use';

const TreeGuildCompanionPlantsPage: FC<PageProps> = () => {
  const guildId = useSearchParam('guildId');
  const treeGuildData = useTreeGuild(guildId);
  const guild = treeGuildData.guild as TreeGuild;
  const tree = useTree(guild?.tree);
  const plants = usePlants(tree);
  const [otherPlantsVisible, setOtherPlantsVisible] = useState(50);
  if (!guild) return null;

  async function addRemovePlant(plant: Plant) {
    await treeGuildData.updateGuild({
      ...guild,
      plants: guild.plants.includes(plant.name)
        ? guild.plants.filter((p) => p !== plant.name)
        : [...guild.plants, plant.name],
    });
  }

  function renderPlantsTable(plantsToShow: Plant[]) {
    const star = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5 m-auto"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
      </svg>
    );

    return (
      <div className="flex flex-col">
        <div className="-my-2 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow border-b border-gray-200 sm:rounded-lg">
              <table className="relative min-w-full divide-y divide-gray-200">
                <thead className="">
                  <tr>
                    <th
                      scope="col"
                      className="sticky top-20 bg-gray-50 z-30 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      style={{ position: 'sticky' }}
                      className="top-20 bg-gray-50 z-30 has-tooltip text-center px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      A
                      <span className="tooltip rounded shadow-lg p-1 bg-gray-100 left-0 top-8">
                        Attractor
                      </span>
                    </th>
                    <th
                      scope="col"
                      style={{ position: 'sticky' }}
                      className="top-20 bg-gray-50 z-30 has-tooltip text-center px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      R
                      <span className="tooltip rounded shadow-lg p-1 bg-gray-100 left-0 top-8">
                        Repeller
                      </span>
                    </th>
                    <th
                      scope="col"
                      style={{ position: 'sticky' }}
                      className="top-20 bg-gray-50 z-30 has-tooltip text-center px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      S
                      <span className="tooltip rounded shadow-lg p-1 bg-gray-100 left-0 top-8">
                        Suppressor
                      </span>
                    </th>
                    <th
                      scope="col"
                      style={{ position: 'sticky' }}
                      className="top-20 bg-gray-50 z-30 has-tooltip text-center px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      M
                      <span className="tooltip rounded shadow-lg p-1 bg-gray-100 left-0 top-8">
                        Mulcher
                      </span>
                    </th>
                    <th
                      scope="col"
                      style={{ position: 'sticky' }}
                      className="top-20 bg-gray-50 z-30 has-tooltip text-center px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      F
                      <span className="tooltip rounded shadow-lg p-1 bg-gray-100 left-0 top-8">
                        Fixer
                      </span>
                    </th>
                    <th
                      scope="col"
                      style={{ position: 'sticky' }}
                      className="top-20 bg-gray-50 z-30 has-tooltip text-center px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Acc
                      <span className="tooltip rounded shadow-lg p-1 bg-gray-100 left-0 top-8">
                        Accumulators
                      </span>
                    </th>
                    <th
                      scope="col"
                      style={{ position: 'sticky' }}
                      className="top-20 bg-gray-50 z-30 has-tooltip text-center px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      GC
                      <span className="tooltip rounded shadow-lg p-1 bg-gray-100 left-0 top-8">
                        Ground cover
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="sticky top-20 bg-gray-50 z-30 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Position
                    </th>
                    <th
                      scope="col"
                      className="sticky top-20 bg-gray-50 z-30 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Water needs
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {plantsToShow.map((plant) => (
                    <tr
                      key={plant.name}
                      className={clsx(
                        'cursor-pointer hover:bg-gray-200 hover:text-black relative',
                        guild.plants.includes(plant.name) &&
                          'bg-secondary hover:bg-secondary-300 text-white hover:text-white'
                      )}
                      onClick={() => addRemovePlant(plant)}
                    >
                      <td
                        className="px-6 py-4 whitespace-nowrap has-tooltip"
                        style={{ position: 'static' }}
                      >
                        <div className="text-sm font-medium">
                          {plant.name}
                          {plant.properties && (
                            <div className="tooltip rounded shadow-lg p-2 pb-0 bg-gray-50 text-black left-4 right-4 bottom-16 whitespace-normal">
                              {plant.properties
                                .split('*')
                                .map((w) => w.trim())
                                .filter(Boolean)
                                .map((it, idx) => ({ it, idx }))
                                .map(({ it, idx }) => (
                                  <p key={idx} className="mb-2">
                                    {it}
                                  </p>
                                ))}
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {plant.attractor && star}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {plant.repeller && star}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {plant.supressor && star}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {plant.mulcher && star}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {plant.fixer && star}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {plant.accumulator && star}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {plant.groundCover && star}
                      </td>
                      <td className="px-6 py-4">
                        {plant.dripLine
                          .map((d) =>
                            d === '1'
                              ? 'Inside Dripline'
                              : d === '2'
                              ? 'On Dripline'
                              : d === '3'
                              ? 'Outside Dripline'
                              : ''
                          )
                          .join(', ')}
                        {}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <PlantWater plant={plant} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderWarnings() {
    const selectedPlants = [
      ...plants.recommendedPlants,
      ...plants.otherPlants,
    ].filter((p) => guild.plants.includes(p.name));

    function status(role: string, ok: boolean) {
      return (
        <div className="text-gray-700 mr-4">
          {ok ? '✔' : '❌'} {role}
        </div>
      );
    }

    const hasAttractor = selectedPlants.some((p) => p.attractor);
    const hasRepeller = selectedPlants.some((p) => p.repeller);
    const hasSuppressor = selectedPlants.some((p) => p.supressor);
    const hasMulcher = selectedPlants.some((p) => p.mulcher);
    const hasFixer = selectedPlants.some((p) => p.fixer);
    const hasAccumulator = selectedPlants.some((p) => p.accumulator);
    const hasGroundCover = selectedPlants.some((p) => p.groundCover);

    const allRoles =
      hasAttractor &&
      hasRepeller &&
      hasSuppressor &&
      hasMulcher &&
      hasFixer &&
      hasAccumulator &&
      hasGroundCover;

    return (
      <div className="sticky z-40 top-0 h-20  -mx-1 px-1 bg-gray-100 flex items-center">
        {status('Attractor', hasAttractor)}
        {status('Repeller', hasRepeller)}
        {status('Suppressor', hasSuppressor)}
        {status('Mulcher', hasMulcher)}
        {status('Fixer', hasFixer)}
        {status('Accumulator', hasAccumulator)}
        {status('Ground cover', hasGroundCover)}

        <div className="flex flex-1 justify-center">
          <Button
            type="submit"
            color="secondary"
            className="flex items-center"
            disabled={!allRoles}
            onClick={() =>
              navigate(`/tree-guild-result/?guildId=${treeGuildData.guild.id}`)
            }
          >
            Show result
          </Button>
        </div>
      </div>
    );
  }

  const title = `${guild.tree} Guild Companion Plants`;
  return (
    <Layout className="relative">
      <SEO
        url="/tree-guild-companion-plants/"
        title={title}
        description={title}
      />

      <div className="container m-auto relative">
        <h1 className="text-3xl text-center font-bold w-full mt-10 mb-4">
          {title}
        </h1>
        <Breadcrumbs
          links={[
            { src: '/tree-guilds/', text: 'My Tree Guilds' },
            {
              src: `/tree-guild/?guildId=${guild.id}`,
              text: `${guild.tree} Guild`,
            },
          ]}
          currentPage="Companion Plants"
        />

        <div className="flex w-full justify-center -mx-2 -my-2 mt-10">
          <div
            className={clsx(
              'shadow-lg rounded-md p-8 m-2 flex flex-col items-center',
              'bg-secondary text-white'
            )}
          >
            <div className="text-center text-7xl mb-4">🌳</div>
            <div className="text-center text-xl">
              {guild.variety ? ` ${guild.variety}` : ''}
            </div>
          </div>

          {guild.soulmate && (
            <div
              className={clsx(
                'shadow-lg rounded-md p-8 m-2 flex flex-col items-center',
                'bg-secondary text-white'
              )}
            >
              <div className="text-center text-7xl mb-4">🌳</div>
              <div className="text-center text-xl">{guild.soulmate}</div>
            </div>
          )}
        </div>

        {renderWarnings()}

        <h2 className="text-xl font-bold mt-10 mb-2">
          Recommended Companion Plants
        </h2>
        <div className="text-gray-700 font-sm mb-4">
          We recommend your guild have the same role met at least 5 times in
          your guild so that if you lose a couple plants on the way your guild
          will still be well protected.
        </div>
        {renderPlantsTable(plants.recommendedPlants)}

        <h2 className="text-xl font-bold mt-10 mb-4">Other Companion Plants</h2>
        {renderPlantsTable(plants.otherPlants.slice(0, otherPlantsVisible))}

        {otherPlantsVisible < plants.otherPlants.length && (
          <div className="flex w-full justify-center mt-10">
            <Button
              type="submit"
              color="secondary"
              className="flex items-center"
              onClick={() => setOtherPlantsVisible((it) => it + 50)}
            >
              Show more plants
            </Button>
          </div>
        )}

        <div className="mb-10" />
      </div>
    </Layout>
  );
};

export default TreeGuildCompanionPlantsPage;
